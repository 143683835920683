body {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

#root {
  max-width: 1000px;
  margin: auto;
}

.content-wrapper {
  /*
    46px - Menu
    20px - footer
    20px - top and buttom padding (10+10)
   */
  min-height: calc(100vh - 46px - 20px - 20px);
  padding: 10px;
}

footer {
  height: 20px;
  text-align: center;
}

.ant-cascader-menu {
  /* empirically chosen value for not to show scroll on faculties */
  height: 194px;
}
